const customStyles = {
  rows: {
    style: {
      minHeight: "70px", // override the row height
      height: "55px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      height: "45px",
      color:"#495056"
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};

export default customStyles;

const customStyles = {
  rows: {
    style: {
      minHeight: "70px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "20px", // override the cell padding for head cells
      paddingRight: "10px",
      height: "45px",
      color: "#495056",
    },
  },
  cells: {
    style: {
      paddingLeft: "20px", // override the cell padding for data cells
      paddingRight: "10px",
    },
  },
};

export default customStyles;

//import ChartDataLabels from "chartjs-plugin-datalabels";

/*

mauve 139 31 165 #8a1fa5
bleu ciel 132 166 206 #84a6ce
vert 0 137 29 #00891e 
orange 255 163 33 #ffa321
rouge 251 0 0 ##fb0000


  */
/*
const data = {
  labels: ["01/01", "01/04", "01/07", "01/10"],
  size: [175, 175, 175, 175],
  weight: [80, 81, 81.5, 82],
  imc: [],

  hba1c_1: [1, 1.5, 2, 1],
  hba1c_2: [4, 4.5, 5, 4],
  hba1c_3: [76, 74, 72, 76],
  hba1c_4: [16, 14, 13, 15],
  hba1c_5: [3, 2, 1, 3],
  cv: [12, 13, 15, 14],

  basal: [55, 57, 60, 56],
  bolus: [45, 43, 40, 44],
  insuline: [85, 84, 82, 83],

  hba1c: [9.18, 9.21, 9.15, 9.14],
  cg: [2, 3, 2, 4, 2],
  tc: [80, 72, 17, 85],
};
*/

export const config1 = {
  type: "bar",
  plugins: {
    title: {
      display: true,
      text: "HbA1c - Données capteur",
      padding: 20,
    },
    legend: {
      position: "bottom",
    },
    datalabels: {
      align: "top",
      offset: -5,
      backgroundColor: function (context) {
        return "#24335D";
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      //formatter: Math.round,
      padding: 6,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    ya: {
      type: "linear",
      display: false,
      position: "right",
      // grid line settings
      grid: {
        drawOnChartArea: true,
      },
      suggestedMin: 0,
      suggestedMax: 20,
    },
    yb: {
      type: "linear",
      display: true,
      position: "left",
      suggestedMax: 100, // minimum will be 0, unless there is a lower value.
      suggestedMin: 0, // minimum will be 0, unless there is a lower value.
      // OR //
      beginAtZero: true, // minimum value will be 0.
      grid: {
        drawOnChartArea: true,
      },
    },
    yc: {
      type: "linear",
      display: true,
      position: "right",
      // grid line settings
      grid: {
        drawOnChartArea: true,
      },
      suggestedMax: 20, // minimum will be 0, unless there is a lower value.
      suggestedMin: 0, // minimum will be 0, unless there is a lower value.
    },
  },
};

export const config2 = {
  type: "bar",
  plugins: {
    title: {
      display: true,
      text: "TIR - Coef. de variation",
      padding: 20,
    },
    legend: {
      position: "bottom",
    },
    datalabels: {
      align: "top",
      offset: -5,
      backgroundColor: function (context) {
        return "#000000";
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      //formatter: Math.round,
      padding: 6,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      // grid line settings
      grid: {
        drawOnChartArea: true, // only want the grid lines for one axis to show up
      },
    },
  },
};

export const config3 = {
  type: "bar",
  plugins: {
    title: {
      display: true,
      text: "Moyenne dose insuline quotidienne - Moyenne répartition Basal/Bolus",
      padding: 20,
    },
    legend: {
      position: "bottom",
    },
    datalabels: {
      align: "top",
      offset: -5,
      backgroundColor: function (context) {
        return "rgb(251,137,26)";
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      //formatter: Math.round,
      padding: 6,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      // grid line settings
      grid: {
        drawOnChartArea: true, // only want the grid lines for one axis to show up
      },
    },
  },
};

export const config4 = {
  type: "bar",
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    title: {
      display: true,
      text: "Poids - Taille - IMC",
      padding: 20,
    },
    legend: {
      position: "bottom",
    },
    datalabels: {
      align: "top",
      offset: -5,
      backgroundColor: function (context) {
        return "#3d3d3d";
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      //formatter: Math.round,
      padding: 6,
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      grid: {
        drawOnChartArea: true, // only want the grid lines for one axis to show up
      },
      suggestedMin: 0, // ??
      suggestedMax: 2, // ??
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
    y2: {
      min: 10,
      suggestedMin: 16, // ??
      suggestedMax: 30, // ??
      type: "linear",
      display: false,
      position: "right",
      // grid line settings
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  },
};

export const dataConfig1 = (data) => ({
  labels: data.labels,
  datasets: [
    {
      label: "HbA1c %",
      data: data.hba1c,
      borderColor: "#24335D",
      backgroundColor: "#24335D",
      yAxisID: "ya",
      type: "line",
      datalabels: { display: true },
    },
    {
      label: "% Données capteur",
      data: data.tc,
      borderColor: "#52BDC8",
      backgroundColor: "#52BDC8",
      yAxisID: "yb",
      type: "line",
      datalabels: { display: false },
    },
    {
      label: "Moyenne du nombre de glycémies",
      data: data.moyglyc,
      backgroundColor: "#E8477D",
      yAxisID: "yc",
      type: "bar",
      datalabels: { display: false },
    },
  ],
});

export const dataConfig2 = (data) => ({
  labels: data.labels,
  datasets: [
    {
      label: "CV",
      data: data.cv,
      borderColor: "#000000",
      backgroundColor: "#000000",
      yAxisID: "y1",
      type: "line",
      datalabels: { display: true },
    },
    {
      label: "TBR",
      data: data.tbr,
      backgroundColor: "rgb(220,20,60)",
      datalabels: { display: false },
    },
    {
      label: "TIR",
      data: data.tir,
      backgroundColor: "#00891e",
      datalabels: { display: false },
    },
    {
      label: "TAR",
      data: data.tar,
      backgroundColor: "#ffa321",
      datalabels: { display: false },
    },
  ],
});

export const dataConfig3 = (data) => ({
  labels: data.labels,
  datasets: [
    {
      label: "Dose Insuline",
      data: data.diq,
      borderColor: "#FBB41C",
      backgroundColor: "#FBB41C",
      yAxisID: "y1",
      type: "line",
      datalabels: { display: true },
    },
    {
      label: "Basal",
      data: data.basal,
      backgroundColor: "#9FA8D5",
      datalabels: { display: false },
    },
    {
      label: "Bolus",
      data: data.bolus,
      backgroundColor: "#E8D5CC",
      datalabels: { display: false },
    },
  ],
});

export const dataConfig4 = (data) => ({
  labels: data.labels,
  datasets: [
    {
      label: "IMC",
      data: data.imc,
      borderColor: "#3d3d3d",
      backgroundColor: "#3d3d3d",
      yAxisID: "y2",
      type: "line",
      datalabels: { display: true },
    },
    {
      label: "Taille",
      data: data.size,
      borderColor: "#b5cfe0",
      backgroundColor: "#b5cfe0",
      yAxisID: "y",
      type: "bar",
      datalabels: { display: false },
    },
    {
      label: "Poids",
      data: data.weight,
      borderColor: "#baa88a",
      backgroundColor: "#baa88a",
      yAxisID: "y1",
      type: "bar",
      datalabels: { display: false },
    },
  ],
});

const conditionalRowStyles = [
  /*{
    when: (row) => String(row.fullName).length >= 22,
    style: {
      backgroundColor: "#EEB9D1",
      "&:hover": {
        backgroundColor: "#F2F3F5",
      },
    },
  },*/
  /*
  {
    when: (row) => !row.lastIntervention,
    style: {
      backgroundColor: "#EEB9D1",
      "&:hover": {
        backgroundColor: "#F2F3F5",
      },
    },
  },
  {
    when: (row) => row.lastIntervention && !row.lastIntervention.documents,
    style: {
      backgroundColor: "#EEB9D1",
      "&:hover": {
        backgroundColor: "#F2F3F5",
      },
    },
  },
  {
    when: (row) =>
      row.lastIntervention &&
      Array.isArray(row.lastIntervention.documents) &&
      row.lastIntervention.documents.length === 0,
    style: {
      backgroundColor: "#EEB9D1",
      "&:hover": {
        backgroundColor: "#F2F3F5",
      },
    },
  },
  */
];

export default conditionalRowStyles;
